
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatI1(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('li', {
                    'className': 'imagestyle--medium productgrid--item productitem--emphasis show-actions--mobile' + (this.on_sale ? ' productitem--sale' : ''),
                    'data-product-item': true,
                    'data-product-quickshop-url': this.url
                }, _createElement('div', {
                    'className': 'productitem',
                    'data-product-item-content': true
                }, _createElement('div', { 'className': 'productitem__container' }, _createElement('div', { 'className': 'productitem__image-container' }, _createElement('a', {
                    'className': 'productitem--image-link',
                    'href': this.url,
                    'data-product-page-link': true
                }, _createElement('figure', {
                    'className': 'productitem--image',
                    'data-product-item-image': true
                }, this.image2 ? _createElement('img', {
                    'className': 'productitem--image-alternate',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '536'
                }) : null, _createElement('img', {
                    'className': 'productitem--image-primary',
                    'alt': this.removeHTML(this.title),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError
                }), this.on_sale ? _createElement('span', {
                    'className': 'productitem__badge productitem__badge--sale',
                    'key': '925'
                }, _createElement('span', { 'data-badge-sales-range': true }, '\n                Save\n                ', _createElement('span', {
                    'className': 'money',
                    'data-price-money-saved': true
                }, '\n                  ', this.formatPrice(this.compare_at_price - this.price), '\n                '))) : null))), _createElement('div', { 'className': 'productitem--info' }, _createElement('div', { 'className': 'price productitem__price' }, _createElement('div', { 'className': 'price__compare-at' }, _createElement('span', { 'className': 'money price__compare-at--single' }, this.formatPrice(this.compare_at_price))), _createElement('div', { 'className': 'price__current price__current--emphasize' + (this.on_sale ? ' price__current--on-sale' : '') }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '18150'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI1.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], _createElement('h2', { 'className': 'productitem--title' }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'data-product-page-link': true
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('span', { 'className': 'productitem--vendor' }, _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('div', { 'className': 'productitem__stock-level' }, _createElement('div', { 'className': 'product-stock-level-wrapper' }, this.inventory_quantity > 0 ? _createElement('span', {
                    'className': 'product-stock-level' + (this.inventory_quantity < 5 ? ' product-stock-level--low' : ' product-stock-level--high'),
                    'key': '2656'
                }, _createElement('span', { 'className': 'product-stock-level__text' }, _createElement('div', { 'className': 'product-stock-level__badge-text' }, '\n                  ', this.inventory_quantity < 5 ? `Only ${ this.inventory_quantity } left!` : `${ this.inventory_quantity } in stock`, '\n                '))) : null, this.inventory_quantity === 0 ? _createElement('span', {
                    'className': 'product-stock-level product-stock-level--none',
                    'key': '3198'
                }, _createElement('span', { 'className': 'product-stock-level__text' }, _createElement('div', { 'className': 'product-stock-level__badge-text' }, 'Out of stock'))) : null))), _createElement('div', {
                    'className': 'productitem--actions',
                    'data-product-actions': true
                }, _createElement('div', { 'className': 'productitem--action quickshop-button' }, _createElement('button', {
                    'onClick': window.Convermax.quickView(this),
                    'className': 'productitem--action-trigger button-secondary mdc-ripple-surface mdc-ripple-upgraded',
                    'data-quickshop-full': true,
                    'type': 'button'
                }, '\n            Quick shop\n          ')), _createElement('div', { 'className': 'productitem--action atc--button' }, this.variant_ids.length === 1 && !this.out_of_stock ? _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'encType': 'multipart/form-data',
                    'key': '3958'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': '1'
                }), _createElement('button', {
                    'className': 'button-primary mdc-ripple-surface mdc-ripple-upgraded productitem--action-atc productitem--action-trigger',
                    'type': 'submit',
                    'aria-label': 'Add to cart'
                }, _createElement('span', { 'className': 'atc-button--text' }, 'Add to cart'))) : null, this.variant_ids.length > 1 && !this.out_of_stock ? _createElement('a', {
                    'href': this.url,
                    'className': 'button-primary mdc-ripple-surface mdc-ripple-upgraded productitem--action-atc productitem--action-trigger',
                    'key': '4517'
                }, _createElement('span', { 'className': 'atc-button--text' }, 'Choose Options')) : null, this.out_of_stock ? _createElement('button', {
                    'className': 'button-secondary mdc-ripple-surface mdc-ripple-upgraded productitem--action-atc productitem--action-trigger',
                    'aria-label': 'Out of stock',
                    'disabled': true,
                    'key': '4812'
                }, _createElement('span', { 'className': 'atc-button--text' }, 'Out of stock')) : null)), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '5118'
                }) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'product-section--title product-recommendations--title cm_related-products-title' }, 'You may also like')), _createElement.apply(this, [
        'div',
        { 'className': 'product-section--content product-row cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []